import { configureStore } from '@reduxjs/toolkit';
import translationReducer from '../../../../common/lib/redux/slices/translationSlice';

export const store = configureStore({
  reducer: {
    translations: translationReducer,
  },
});

export type CognosPEState = ReturnType<typeof store.getState>;
export type CognosPEDispatch = typeof store.dispatch;

export default store;
