import messages from '../locales/messages';
import { Tag, NumberInput } from 'carbon-components-react';
import './SaaSParameters.scss';
import { useRef, MutableRefObject, useState, useEffect } from 'react';
import {
  defaultPremiumLicenses,
  defaultStandardLicenses,
  maxLicenses,
  minLicenses,
} from '../constants/defaults';
import { segmentTracking } from '../lib/tracker';
import { createUseTranslation } from '../../../common/hooks/widgets/useTranslation';
import { useCognosPESelector } from '../lib/redux/hooks';
import { convertToString } from './CognosPriceEstimator';

export interface SaaSParametersProps {
  premiumLicenses: number;
  standardLicenses: number;
  setStandardLicenses: React.Dispatch<React.SetStateAction<number>>;
  setPremiumLicenses: React.Dispatch<React.SetStateAction<number>>;
}
export interface ParameterProps {
  ParameterTitle: string;
  ParameterSubtitle: string;
  Features: string[];
  InputName: string;
  defaultValueInput: number;
  setInputNumber: React.Dispatch<React.SetStateAction<number>> | null;
  maxValue: number;
  cta: string;
}
const Parameter = ({
  ParameterTitle,
  ParameterSubtitle,
  Features,
  InputName,
  defaultValueInput,
  setInputNumber,
  maxValue,
  cta,
}: ParameterProps) => {
  const userRef = useRef() as MutableRefObject<HTMLInputElement>;
  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
          <p className="lineTitle">{ParameterTitle}</p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
          <p className="lineSubtitle">{ParameterSubtitle}</p>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 featuresTags">
          <div>
            {Features.map((item: string, index: number) => (
              <Tag
                key={index}
                type={'purple'}
                size="md"
                disabled={false}
                filter={false}
              >
                {item}
              </Tag>
            ))}
          </div>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-4 bx--col-md-2 bx--col-2">
          {InputName.length > 0 ? (
            <NumberInput
              id={InputName}
              ref={userRef}
              value={defaultValueInput}
              min={minLicenses}
              max={maxValue}
              className="NumberInput"
              invalidText={
                messages.Saas_license_error.defaultMessage + maxValue
              }
              onChange={() => {
                if (setInputNumber) {
                  setInputNumber(Number(userRef.current.value));
                }
                segmentTracking('CTA Clicked', {
                  pageTitle: 'Cognos Analytics Price Estimator page',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: cta,
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
              }}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

const SaaSParameters = ({
  premiumLicenses,
  standardLicenses,
  setPremiumLicenses,
  setStandardLicenses,
}: SaaSParametersProps) => {
  const AEMMessages = useCognosPESelector(
    (state) => state.translations.messages,
  );
  const useTranslation = createUseTranslation(messages, AEMMessages);
  const SaaSFeaturesStd = [
    convertToString(useTranslation('Saas_feature_1', true)),
    convertToString(useTranslation('Saas_feature_2', true)),
    convertToString(useTranslation('Saas_feature_3', true)),
    convertToString(useTranslation('Saas_feature_4', true)),
    convertToString(useTranslation('Saas_feature_5', true)),
    convertToString(useTranslation('Saas_feature_6', true)),
  ];

  const SaaSFeaturesPrm = [
    convertToString(useTranslation('Saas_prem_feature_1', true)),
    convertToString(useTranslation('Saas_prem_feature_2', true)),
    convertToString(useTranslation('Saas_prem_feature_3', true)),
  ];

  const [maxStandard, setMaxStandard] = useState(140);
  const [maxPremium, setMaxPremium] = useState(140);

  useEffect(() => {
    setMaxStandard(maxLicenses - premiumLicenses);
    setMaxPremium(maxLicenses - standardLicenses);
  }, [premiumLicenses, standardLicenses]);

  return (
    <>
      <Parameter
        ParameterTitle={convertToString(useTranslation('Saas_line_1', true))}
        ParameterSubtitle={convertToString(useTranslation('Saas_line_2', true))}
        Features={SaaSFeaturesStd}
        InputName="field_1"
        defaultValueInput={defaultStandardLicenses}
        setInputNumber={setStandardLicenses}
        maxValue={maxStandard}
        cta={messages.Saas_line_1.defaultMessage}
      />
      <Parameter
        ParameterTitle={convertToString(
          useTranslation('Saas_prem_line_1', true),
        )}
        ParameterSubtitle={convertToString(
          useTranslation('Saas_prem_line_2', true),
        )}
        Features={SaaSFeaturesPrm}
        InputName="field_2"
        defaultValueInput={defaultPremiumLicenses}
        setInputNumber={setPremiumLicenses}
        maxValue={maxPremium}
        cta={messages.Saas_prem_line_1.defaultMessage}
      />
    </>
  );
};

export { SaaSParameters, Parameter };
