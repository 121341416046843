import { useState, ReactElement, useEffect } from 'react';
import messages from '../locales/messages';
import 'src/common/style/common.scss';
import { ThanksModal, ThanksTableVerbiage } from 'src/common/utils/ThanksModal';
import { createUseTranslation } from '../../../common/hooks/widgets/useTranslation';
import { useCognosPESelector } from '../lib/redux/hooks';
import { convertToString } from './CognosPriceEstimator';

export interface thanksProps {
  preference: string;
  kindService: string;
  stdLiceses: number;
  preLicenses: number;
  monthlyCost: string;
  bpName: string;
}

const Thanks = ({
  preference,
  kindService,
  stdLiceses,
  preLicenses,
  monthlyCost,
  bpName,
}: thanksProps): ReactElement => {
  const [, setShowThanksModal] = useState(true);
  const AEMMessages = useCognosPESelector(
    (state) => state.translations.messages,
  );
  const useTranslation = createUseTranslation(messages, AEMMessages);

  const titleThanks: ThanksTableVerbiage[] = [
    {
      group: 'title',
      value: convertToString(useTranslation('thank_you', true)),
    },
    {
      group: 'subTitle',
      value: convertToString(useTranslation('thankyou_received', true)),
    },
  ];

  const widgetTitleThanks: ThanksTableVerbiage[] = [
    {
      group: 'widgetTitle1',
      value: convertToString(useTranslation('thanyou_req_detail', true)),
    },
  ];

  const contentThanks: ThanksTableVerbiage[] = [
    {
      group: convertToString(useTranslation('thanyou_preferences', true)),
      value: preference,
    },
    {
      group: convertToString(useTranslation('thanyou_kind', true)),
      value: kindService,
    },
    {
      group: convertToString(useTranslation('thankyou_stdLicenses', true)),
      value: String(stdLiceses),
    },
    {
      group: convertToString(useTranslation('thankyou_preLicenses', true)),
      value: String(preLicenses),
    },
    {
      group: convertToString(useTranslation('thankyou_monthlyCost', true)),
      value: monthlyCost,
    },
  ];

  bpName &&
    contentThanks.push({
      group: convertToString(useTranslation('thanyou_bp_title', true)),
      value: bpName,
    });

  const buttonThanks: ThanksTableVerbiage[] = [
    {
      group: 'button1',
      value: convertToString(useTranslation('thankyou_return_est_text', true)),
    },
  ];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, [monthlyCost, stdLiceses, preLicenses]);
  return (
    <ThanksModal
      showThanksModal={showModal}
      pageTitle="Cognos Price Estimator Page"
      object="Pricing Page"
      productTitle={'Cognos Price Estimator'}
      pageUrl={'/products/cognos-analytics/pricing/'}
      location={'Estimator'}
      titleThanks={titleThanks}
      widgetTitleThanks={widgetTitleThanks}
      contentThanks={contentThanks}
      buttonThanks={buttonThanks}
      setShowThanksModal={setShowThanksModal}
    />
  );
};

export default Thanks;
