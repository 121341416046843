import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Title: {
    id: '0ElWU8',
    defaultMessage: 'Price Estimator',
  },
  Subtitle: {
    id: '02IkwQ',
    defaultMessage:
      'Estimate the price for your IBM Cognos Analytics preferences',
  },
  Switcher_1: {
    id: 'lJHKNG',
    defaultMessage: 'Cloud On-demand',
  },
  Switcher_2: {
    id: 'r3C4x/',
    defaultMessage: 'Software',
  },
  Radio_saas: {
    id: 'lJHKNG',
    defaultMessage: 'Cloud On-demand',
  },
  Radio_saas_paragraph: {
    id: 'hrt3di',
    defaultMessage:
      'A multi-tenant SaaS environment managed by IBM having access to latest product innovations, ideal for growing companies.',
  },
  Radio_cloud: {
    id: '8lC6C0',
    defaultMessage: 'Cloud Hosted',
  },
  Radio_cloud_paragraph: {
    id: 'y4ScWa',
    defaultMessage:
      'Dedicated cloud environment perfect for the large enterprises, integrating with existing authentication providers and VPN access to on-premise data.',
  },
  Radio_software: {
    id: 'r3C4x/',
    defaultMessage: 'Software',
  },
  Radio_software_paragraph: {
    id: 'q2ZE6o',
    defaultMessage:
      'Available as stand-alone software package or as Cognos cartridge for Cloud Park for Data. Deploy on-prem or on your preferred cloud virtual (IaaS) infraestructure.',
  },
  Saas_line_1: {
    id: 'cqHAzY',
    defaultMessage: 'Number of Cloud Standard licenses',
  },
  Saas_line_2: {
    id: 'IZH6Po',
    defaultMessage: 'Features:',
  },
  Saas_feature_1: {
    id: 'ZpWi38',
    defaultMessage: 'Dashboarding',
  },
  Saas_feature_2: {
    id: 'S1JcRN',
    defaultMessage: 'Analytics Assistant',
  },
  Saas_feature_3: {
    id: 'i/O7gT',
    defaultMessage: 'Mobile app',
  },
  Saas_feature_4: {
    id: 'vh6lLf',
    defaultMessage: 'Receive email reports',
  },
  Saas_feature_5: {
    id: 'Z3YdZF',
    defaultMessage: 'Automated forecasting',
  },
  Saas_feature_6: {
    id: 'w5R1gi',
    defaultMessage: 'Predictive Analytics',
  },
  Saas_prem_line_1: {
    id: 'v3jNeX',
    defaultMessage: 'Number of Cloud Premium licenses',
  },
  Saas_license_error: {
    id: '1WhyEd',
    defaultMessage: 'Number of licenses not should be higher than ',
  },
  Saas_prem_line_2: {
    id: '6SNoHG',
    defaultMessage: 'All the features of Standard, plus:',
  },
  Saas_prem_feature_1: {
    id: 'uiPCvI',
    defaultMessage: 'Enterprise reporting',
  },
  Saas_prem_feature_2: {
    id: 'chBRNR',
    defaultMessage: 'Data exploration',
  },
  Saas_prem_feature_3: {
    id: 'u5Lh/i',
    defaultMessage: 'Custom groups and roles',
  },
  Saas_footnote: {
    id: 'YLAevr',
    defaultMessage:
      'Calculated prices are estimates provided for planning purposes only taking intro account potential IBM discounts, and are not a formal offer from IBM on an IBM Business Partner. The estimation may reflect the total discounted price. Certain factors such as configuration, add-ons, additional requirimients or services will addect final price. IBM Business Partners set and provide their own IBM Cognos Analytics pricing, and neither IBM nor IBM Business Partners are bound by the provided estimate.',
  },
  Cloud_line_1: {
    id: '+Q3dd+',
    defaultMessage: ' ',
  },
  Cloud_line_2: {
    id: 'xvXFuQ',
    defaultMessage: 'All the features of Premium plus:',
  },
  Cloud_feature_1: {
    id: 'AzkkNj',
    defaultMessage: 'Dedicated environment',
  },
  Cloud_feature_2: {
    id: 'iQhr2W',
    defaultMessage: 'Proactive alerts',
  },
  Cloud_feature_3: {
    id: '5O41cV',
    defaultMessage: 'Scheduling and bursting',
  },
  Cloud_feature_4: {
    id: 'MoRYLl',
    defaultMessage: 'Integration with IBM Watson Studio',
  },
  Software_line_1: {
    id: '+Q3dd+',
    defaultMessage: ' ',
  },
  Software_line_2: {
    id: 'IZH6Po',
    defaultMessage: 'Features:',
  },
  Software_feature_1: {
    id: 'ZpWi38',
    defaultMessage: 'Dashboarding',
  },
  Software_feature_2: {
    id: 'o0/SZQ',
    defaultMessage: 'AI assistant',
  },
  Software_feature_3: {
    id: 'i/O7gT',
    defaultMessage: 'Mobile app',
  },
  Software_feature_4: {
    id: 'cMOQhP',
    defaultMessage: 'Create and run reports',
  },
  Software_feature_5: {
    id: 'iQhr2W',
    defaultMessage: 'Proactive alerts',
  },
  Software_feature_6: {
    id: 'chBRNR',
    defaultMessage: 'Data exploration',
  },
  Software_feature_7: {
    id: '5O41cV',
    defaultMessage: 'Scheduling and bursting',
  },
  Software_feature_8: {
    id: 'QPvMlo',
    defaultMessage: 'Jupyter notebook & python',
  },
  Software_feature_9: {
    id: 'pJi9QC',
    defaultMessage: 'Cognos SDK',
  },
  Software_feature_10: {
    id: 'M8fnTY',
    defaultMessage: 'Security and user management',
  },
  Software_feature_11: {
    id: 'IZMYF6',
    defaultMessage:
      'Data preparation and connectivity to cloud and on premise data',
  },
  SaaS_Table_Header_1: {
    id: 'awzgp5',
    defaultMessage: 'Requirements',
  },
  SaaS_Table_Header_2: {
    id: 'b1zuN9',
    defaultMessage: 'Price',
  },
  SaaS_Table_2_1: {
    id: 'jryvMn',
    defaultMessage: 'Cloud Standard licenses',
  },
  SaaS_Table_3_1: {
    id: 'QH30UA',
    defaultMessage: 'Cloud Premium licenses',
  },
  SaaS_Table_4_1: {
    id: 'Rl95tn',
    defaultMessage: 'Total estimated monthly cost',
  },
  SaaS_Table_5_1: {
    id: 'P9v4Ff',
    defaultMessage: 'Total estimated yearly cost',
  },
  SaaS_Table_6_1: {
    id: '5xQFEx',
    defaultMessage: '*Price shown do not include tax',
  },
  SaaS_Table_month: {
    id: 'Cu3Cty',
    defaultMessage: 'month',
  },
  SaaS_CTA_1: {
    id: 'u5AfPm',
    defaultMessage: 'Buy now',
  },
  SaaS_CTA_2: {
    id: 'r4tIGb',
    defaultMessage: 'Request a quote',
  },
  Generic_text: {
    id: 'DR5KAe',
    defaultMessage:
      'For this offering please reach out to us for a personalized quote',
  },
  thank_you: {
    id: 'aYIUar',
    defaultMessage: 'Thank you!',
  },
  thankyou_received: {
    id: 'PwjLGb',
    defaultMessage: 'We have received your request',
  },
  thanyou_req_detail: {
    id: '0L49M8',
    defaultMessage: 'Request details:',
  },
  thanyou_preferences: {
    id: 'LUqj3k',
    defaultMessage: 'Preference:',
  },
  thanyou_kind: {
    id: 'S2W5hk',
    defaultMessage: 'Kind of service:',
  },
  thankyou_stdLicenses: {
    id: 'zfUNAt',
    defaultMessage: 'Number of cloud standard licenses: ',
  },
  thankyou_preLicenses: {
    id: 'GcD5KS',
    defaultMessage: 'Number of cloud premium licenses: ',
  },
  thankyou_monthlyCost: {
    id: 'wF4B3s',
    defaultMessage: 'Monthly cost: ',
  },
  thanyou_bp_title: {
    id: 'BkHBGW',
    defaultMessage: 'Selected Business Partner',
  },
  thankyou_return_est_text: {
    id: '8fgwsI',
    defaultMessage: 'Return to price estimator',
  },
});

export default messages;
