import React, { useState } from 'react';
import messages from '../locales/messages';
import { Button, ButtonSet } from 'carbon-components-react';
import './TableResult.scss';
import { ArrowRight16 } from '@carbon/icons-react';
import { segmentTracking } from '../lib/tracker';
import Cookies from 'js-cookie';
import * as URX_FORM from '../constants/urx-form';
import { useCookiesURL } from 'src/common/hooks/widgets/useCookiesURL';
import { initUrxForm } from 'src/common/hooks/widgets/urxForm';
import { useEnv } from 'src/common/hooks/widgets/useEnv';
import { swapCountryAndLanguage } from 'src/common/utils/swapCountryAndLanguage';
import { IBMLocale } from 'src/common/locale/mapValidLocale';
import { createUseTranslation } from '../../../common/hooks/widgets/useTranslation';
import { useCognosPESelector } from '../lib/redux/hooks';

export interface ResultTableProps {
  radioSelected: string;
  cloudStandarPrice: string;
  cloudPremiumPrice: string;
  monthlyCost: string;
  yearlyCost: string;
  preference: string;
  standardLincenses: number;
  premiumLicenses: number;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  langCode: string;
  companyId: string;
}
const ResultTable = ({
  radioSelected,
  cloudStandarPrice,
  cloudPremiumPrice,
  monthlyCost,
  yearlyCost,
  preference,
  standardLincenses,
  premiumLicenses,
  setDrawerOpen,
  langCode,
  companyId,
}: ResultTableProps) => {
  const cookieURL = useCookiesURL();
  const [environment] = useEnv();
  const localeCode = swapCountryAndLanguage(langCode as IBMLocale);
  const [urxlang] = useState(localeCode.toString());
  const setCookie = () => {
    Cookies.set(
      'urxdcq',
      JSON.stringify({
        source: URX_FORM.FORM_ID,
        // One field to provide all values (stringify)
        dcq: {
          Q_NOTESDATA:
            cookieURL +
            ' Preference: ' +
            preference +
            ' | Option: ' +
            radioSelected +
            ' | Number Cloud Standard Licenses:: ' +
            standardLincenses +
            ' | Number Cloud Premium Licenses:' +
            premiumLicenses +
            ' | Estimated Monthly cost:' +
            monthlyCost +
            ' | Estimated Yearly cost:' +
            yearlyCost,
        },
      }),
    );
  };

  const AEMMessages = useCognosPESelector(
    (state) => state.translations.messages,
  );
  const useTranslation = createUseTranslation(messages, AEMMessages);

  return (
    <>
      <div className="bx--row">
        <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 cognos_analytics_table">
          {radioSelected === 'Cloud On-demand' ? (
            <>
              <div className="bx--row price_table_comp__heading TabBorder">
                <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-3">
                  <p className="Table_header">
                    {useTranslation('SaaS_Table_Header_1')}
                  </p>
                </div>
                <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-1">
                  <p className="Table_header">
                    {useTranslation('SaaS_Table_Header_2')}
                  </p>
                </div>
              </div>
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-3">
                  <p className="Table_row Concept_row">
                    {standardLincenses} {useTranslation('SaaS_Table_2_1')}
                  </p>
                </div>
                <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-1">
                  <p className="Table_row">
                    {cloudStandarPrice}
                    {'/'}
                    {useTranslation('SaaS_Table_month')}
                  </p>
                </div>
              </div>
              <div className="bx--row TabBorder">
                <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-3">
                  <p className="Table_row Concept_row">
                    {premiumLicenses} {useTranslation('SaaS_Table_3_1')}
                  </p>
                </div>
                <div className="bx--col-lg-5 bx--col-md-3 bx--col-sm-1">
                  <p className="Table_row">
                    {cloudPremiumPrice}
                    {'/'}
                    {useTranslation('SaaS_Table_month')}
                  </p>
                </div>
              </div>

              <div className="bx--row Table_mar_06">
                <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-3">
                  <p className="Table_row">
                    {useTranslation('SaaS_Table_4_1')}
                    <sup className="superIndex">1</sup>
                  </p>
                </div>

                <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-1">
                  <p className="Price">{monthlyCost} *</p>
                </div>
              </div>

              <div className="bx--row">
                <div className="bx--col-lg-10 bx--col-md-5 bx--col-sm-3">
                  <p className="Table_row">
                    {useTranslation('SaaS_Table_5_1')}
                    <sup className="superIndex">1</sup>
                  </p>
                </div>
                <div className="bx--col-lg-6 bx--col-md-3 bx--col-sm-1">
                  <p className="Price">{yearlyCost} *</p>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          <div className="bx--row Table_row">
            <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
              <p
                className={
                  radioSelected === 'Cloud On-demand'
                    ? 'priceFootnote'
                    : 'Table_row'
                }
              >
                {radioSelected === 'Cloud On-demand'
                  ? useTranslation('SaaS_Table_6_1')
                  : useTranslation('Generic_text')}
              </p>
            </div>
          </div>
          <ButtonSet className="btnSet">
            {radioSelected === 'Cloud On-demand' ? (
              <Button
                kind="primary"
                className="btn"
                renderIcon={ArrowRight16}
                onClick={() => {
                  segmentTracking('CTA Clicked', {
                    pageTitle: 'Cognos Analytics Price Estimator page',
                    object: 'Pricing page',
                    resultValue: 'CTA clicked',
                    CTA: 'Buy now on IBM Marketplace',
                    location: 'Estimator',
                    action: '',
                    field: '',
                  });
                  window.open(
                    'https://www.ibm.com/marketplace/purchase/configuration/en/checkout?editionID=EID2RPAX&cognospromocta1Lnavtour=true',
                  );
                  setCookie();
                }}
              >
                {useTranslation('SaaS_CTA_1')}
              </Button>
            ) : (
              ''
            )}
            <Button
              kind={
                radioSelected === 'Cloud On-demand' ? 'tertiary' : 'primary'
              }
              className={
                radioSelected === 'Cloud On-demand' ? 'btn' : 'btnfull'
              }
              renderIcon={ArrowRight16}
              onClick={() => {
                segmentTracking('CTA Clicked', {
                  pageTitle: 'Cognos Analytics Price Estimator',
                  object: 'Pricing page',
                  resultValue: 'CTA clicked',
                  CTA: 'Request a quote',
                  location: 'Estimator',
                  action: '',
                  field: '',
                });
                setDrawerOpen(true);
                initUrxForm(
                  environment,
                  URX_FORM.INSTANCE_ID,
                  URX_FORM.FORM_ID,
                  urxlang as IBMLocale,
                  companyId,
                );
                setCookie();
              }}
            >
              {useTranslation('SaaS_CTA_2')}
            </Button>
          </ButtonSet>
        </div>
      </div>
    </>
  );
};

export default ResultTable;
